import { Flex, Heading, Icon } from '@chakra-ui/react';
import { RiCheckboxCircleFill, RiErrorWarningFill } from 'react-icons/ri'
import { H3 } from 'src/typography/typography';

function Toast({ title = '', description, variant = 'success' }: ToastProps) {
  const getIcon = () => {
    switch (variant) {
      case 'success': return RiCheckboxCircleFill
      case 'error':
      case 'warning': return RiErrorWarningFill
    }
  }

  return (
    <Flex
      mx={4}
      mb={4}
      px={4}
      py={3}
      maxW='320px'
      bg={`${variant}.default`}
      borderRadius='md'
      align='flex-start'
    >
      <Icon
        mt={1}
        color='white'
        as={getIcon()}
        mr={3}
      />
      {title && <H3 color='white'>
        {title}
      </H3>}
      <Heading
        lineHeight={1.6}
        as='h5'
        textStyle='p'
        color='white'
      >
        {description}
      </Heading>
    </Flex>
  );
}

interface ToastProps {
  title?: string;
  description: string;
  variant?: 'success' | 'error' | 'warning';
}

export default Toast