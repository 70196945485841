import { Box } from '@chakra-ui/react'
import NextImage from 'src/common/NextImage'

function FormHeader() {
  return (
    <Box
      h='116px'
      w='100%'
      position='relative'
    >
      <NextImage
        priority
        layout='fill'
        quality={100}
        objectFit='cover'
        borderTopLeftRadius='md'
        borderTopRightRadius='md'
        src='/projectlink-stacks.png'
      />
      <Box
        top='50%'
        left='50%'
        position='absolute'
        transform='translate(-50%, -50%)'
      >
        <NextImage
          priority
          dimensions={[59, 49]}
          src='/projectlink-white.png'
          alt='projectlink-factory'
        />
      </Box>
    </Box>
  )
}

export default FormHeader
