import { useState } from 'react'

/**
 * rudimentary validation for text inputs
 * 
 * @param test - fn invoked with the test string
 */
function useInputValidation(test: (p: string) => boolean) {
  const [isValid, setIsValid] = useState(false)
  const validate = (value: string) => setIsValid(test(value))

  return {
    isValid,
    validate,
  }
}

export default useInputValidation