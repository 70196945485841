import { Box, Heading } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForeground } from 'src/modeAwareColors';
import { routes } from 'src/navigation/util';
import { H3 } from 'src/typography/typography';
import EmailButton from './EmailButton';
import GoogleButton from './GoogleButton';

function LoginOptions(props) {
  const { onNext, display, onSuccess } = props
  const router = useRouter()

  return (
    <Box
      display={display}
      layerStyle='cardPadding'
    >
      <H3
        as='h1'
        textAlign='center'
        mt={0}
        mb={6}
      >
        {router.route === routes.login ? 'Login to Projectlink' : 'Create a new account'}
      </H3>
      <GoogleButton onSuccess={onSuccess} />
      <EmailButton onClick={onNext} mb={0} />
    </Box>
  );
}

LoginOptions.displayName = "LoginOptions"
export default LoginOptions
