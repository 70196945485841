import { useRouter } from "next/router"

/**
 * returns the 'next' query parameter, which indicates the next route to
 * send a user to, usually after an action like creating a project.
 *
 * keeps the existing query params
 */
function useNextRoute() {
  const { query } = useRouter()
  const qs = Object.keys(query).map(key => `${key}=${query[key]}`).join('&')
  return query['next'] && `${query['next']}${qs ? '?' + qs : ''}`
}

export default useNextRoute
