import { AiOutlineGoogle } from 'react-icons/ai'

import { windowContext } from '../util'
import logger from '../util/logger';
import firebase from '../util/firebaseWeb';
import useAuth from '../auth/useAuth';
import LoginButton from './Button';
import { createDocuments } from 'src/access/auth';
import useUserContext from 'src/hooks/useUserContext';
import analytics from 'src/util/analytics';
import { useRouter } from 'next/router';
import { routes } from 'src/navigation/util';
import { useBackground } from 'src/modeAwareColors';
import { useEffect, useState } from 'react';

/**
 * renders a button which opens the firebase auth modal
 */
function GoogleButton({ onSuccess }) {
  const { isLoading, setIsLoading } = useAuth()
  const { user } = useUserContext()
  const router = useRouter()
  const [isNewUser, setIsNewUser] = useState(false)

  const signIn = async () => {
    if (windowContext()) {
      setIsLoading(true);
      const provider = new firebase.auth.GoogleAuthProvider()
      // adding contacts scope requires app verification from Google
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
      try {
        const credential = await firebase.auth().signInWithPopup(provider)
        if (credential.additionalUserInfo.isNewUser) {
          setIsNewUser(true)
          return
        }
        await onSuccess()
      } catch (e) {
        logger.error(e)
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    const finishSignup = async () => {
      // @ts-ignore same shit
      await createDocuments(user)
      analytics.logEvent('sign_up', {
        provider: 'google',
        display_name: user.displayName,
      })
      await router.push(routes.onboarding)
    }

    if (isNewUser && user?.uid) {
      finishSignup()
    }

  }, [user, isNewUser])

  return (
    <LoginButton
      onClick={signIn}
      isLoading={isLoading}
      bg='success.default'
      color='white'
      borderColor='success.default'
      _hover={{
        bg: useBackground(),
        color: 'success.default',
      }}
      leftIcon={<AiOutlineGoogle color='' />}
    >
      Continue with Google
    </LoginButton>
  )
}

export default GoogleButton
