import { Button, chakra } from '@chakra-ui/react'
import { DomainObjects } from 'projectlink-common'
import { cloneElement, createContext, useContext, useState, FC } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import logError from 'src/access/errorLogger'

// todo: type this
const ctx = createContext<any>({})
const useStepFormContext = () => useContext(ctx)
const StepFormProvider = ctx.Provider

function useStepForm({ initialIndex, showStepIndicator }) {
  const [isLoading, setIsLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(initialIndex)
  const onNext = () => setActiveIndex(prev => prev + 1)
  const onBack = () => setActiveIndex(prev => Math.max(prev - 1, 0))

  const methods = useForm({ mode: 'onChange' })

  return {
    activeIndex,
    onNext,
    onBack,
    isLoading,
    setIsLoading,
    showStepIndicator,
    ...methods,
  }
}

function useStep(props) {
  const { onNext, onBack, ...rest } = useStepFormContext()

  return cloneElement(props.children, {
    ...props,
    onNext,
    onBack,
    ...rest,
  })
}

function StepForm({
  initialIndex = 0,
  showStepIndicator = true,
  children,
  /**
   * async function that's called when form submits
   * receives form data as param
   */
  onSubmit,
  onSubmitError = (error: any) => null,
}) {
  const value = useStepForm({ initialIndex, showStepIndicator })

  return (
    <StepFormProvider value={value}>
      <Form
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
      >
        {children}
      </Form>
    </StepFormProvider>
  )
}

/**
 * this extra wrapper component is needed just to pass onSubmit
 * to an html form element
 */
function Form({ children, onSubmit, onSubmitError }) {
  const {
    setIsLoading,
    activeIndex,
    isLast,
    onBack,
    onNext,
    handleSubmit,
  } = useStepFormContext()

  const _onSubmit = async (formData) => {
    try {
      setIsLoading(true)
      await onSubmit(formData)
      if (!isLast) onNext() // if the form submits and we are not on the last step, go to the next step
      setIsLoading(false)
    } catch (e) {
      onBack()
      onSubmitError(e)
      setIsLoading(false)
      logError(e)
    }
  }

  const filtered = children.filter(child => !(child.props?.hideStep))
  const stepNames = filtered
    .map(child => child.props.name)

  return (
    <chakra.form onSubmit={handleSubmit(_onSubmit)} >
      {filtered.map((child, index) => (
        cloneElement(child, {
          isActive: index === activeIndex,
          isLast: index === filtered.length - 1,
          display: index === activeIndex ? 'inherit' : 'none',
          currentIndex: index,
          name: child.props.name,
          key: child.props.name,
          activeIndex,
          steps: stepNames,
        })
      ))}
    </chakra.form>
  )
}

const Step: FC<{ name: string, hideStep?: boolean }> = (props) => {
  const children = useStep(props)
  return children
}

const StepFormContinueButton: FC<{
  onNext: () => void,
  isSubmit: boolean,
  isLoading?: boolean,
  isDisabled?: boolean,
}> =
  ({ onNext, isSubmit = false, isLoading = false, isDisabled = false }) => {

    return (
      <Button
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant={isSubmit ? 'success' : 'default'}
        type={isSubmit ? 'submit' : 'button'}
        onClick={onNext}
      >
        {isSubmit
          ? <>Done</>
          : <>Next →</>}
      </Button>
    )
  }

export interface ImplicitStepProps extends UseFormMethods {
  onNext: () => void,
  onBack: () => void,
  isActive: boolean,
  isLoading: boolean,
  isLast: boolean,
  showStepIndicator?: boolean
  type: DomainObjects,
  activeIndex: number,
  currentIndex: number,
  display: 'inherit' | 'none',
  steps: string[],
}

export {
  StepFormContinueButton,
  StepForm,
  Step,
}
