/**
 * logger 
 */
const logger = {
  error: (e: Error, msg?: string) => {
    console.error(e, msg)
  },

  warn: (msg?: string) => {
    console.warn(msg)
  },

  debug: (msg?: string) => {
    console.debug(msg)
  },

  info: (msg?: string) => {
    console.info(msg)
  },

  log: (msg?: string) => {
    console.log(msg)
  },
}

export default logger
