import { A } from 'src/typography/typography';

function BackButton({ onClick, ...rest }) {
  return (
    <A
      onClick={onClick}
      {...rest}
    >
      ← Back
    </A>
  );
}

export default BackButton
