import { useRouter } from 'next/router'
import LoginFormContainer from './LoginFormContainer'
import LoginOptions from './LoginOptions'
import EmailFieldSet from './EmailFieldSet'
import { emailSignin } from '../access/auth'
import { routes } from 'src/navigation/util'
import { Step, StepForm } from 'src/common/StepForm'
import EmailSignupConfirmation from './EmailSignupConfirmation'
import useLocallyStoredActions from 'src/hooks/useLocallyStoredActions'
import useUserContext from 'src/hooks/useUserContext'
import { useEffect, useRef, useState } from 'react'
import { ActionTypes, addAction } from 'src/util/locallyStoredActions'
import useNextRoute from 'src/hooks/useNextRoute'

function Authenticate({ children, ...rest }) {
  const router = useRouter()
  const locallyStoredActions = useLocallyStoredActions()
  const { user } = useUserContext()
  const [googleLoginSucceeded, setGoogleLoginSucceeded] = useState(false)
  const next = useNextRoute()
  const suppressRedirect = useRef(false)

  const onSuccessfulGoogleLogin = () => {
    setGoogleLoginSucceeded(true)
  }

  const finishGoogleSignin = async () => {
    const locallyStoredAction = await locallyStoredActions()
    if (!locallyStoredAction) {
      if (next) return await router.push(next)
      await router.push(routes.dashboard)
    }
  }

  const onSubmit = async ({ email, displayName }) => {
    window.localStorage.setItem('signInEmail', email)
    if (displayName) window.localStorage.setItem('displayName', displayName)
    await emailSignin(email, null)
  }

  useEffect(() => {
    if (router?.query?.next) {
      addAction({
        action: ActionTypes.redirect,
        payload: router.query.next as string,
      })
    }
  }, [router?.query])

  useEffect(() => {
    if (user?.uid && googleLoginSucceeded) {
      suppressRedirect.current = true // let this codepath handle the rediret
      finishGoogleSignin()
    }
  }, [user, googleLoginSucceeded])

  useEffect(() => {
    /**
     * this is meant for if a user lands here but is
     * already signed in just redirect to the dashbaord
     */
    if (user?.uid && !suppressRedirect.current) router.push(routes.dashboard)
  }, [user, suppressRedirect.current])

  return children({ onSubmit, onSuccessfulGoogleLogin })
}

function Login() {
  return (
    <Authenticate>
      {({ onSubmit, onSuccessfulGoogleLogin }) => (
        <LoginFormContainer>
          <StepForm onSubmit={onSubmit}>
            <Step name='options'>
              <LoginOptions
                onSuccess={onSuccessfulGoogleLogin}
              />
            </Step>
            <Step name='email'>
              <EmailFieldSet />
            </Step>
            <Step name='message'>
              <EmailSignupConfirmation />
            </Step>
          </StepForm>
        </LoginFormContainer>
      )}
    </Authenticate>
  )
}

export { Authenticate }
export default Login
