import { Box, Icon, Stack } from "@chakra-ui/react";
import { AiFillCheckCircle } from "react-icons/ai";
import { A, Caption, H4 } from "src/typography/typography";
import BackButton from "./BackButton";

function EmailSignupConfirmation(props) {
  const { onBack, display } = props

  return (
    <Box
      display={display}
    >
      <Stack
        layerStyle='cardPadding'
        spacing='md'
      >
        <H4 display='flex' alignItems='center'>
          <Icon as={AiFillCheckCircle} color='cyan.default' mr={2} /> Please check your email
        </H4>
        <Caption>
          Email authentication is a powerful way to secure your account. You may need to check your spam or filtered inboxes
        </Caption>
      </Stack>
      <Box
        layerStyle='cardFooter'
        alignItems='center'
        justifyContent='flex-start'
      >
        <BackButton onClick={onBack} />
      </Box>
    </Box>
  )
}

export default EmailSignupConfirmation
