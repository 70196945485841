import { Caption, Hyperlink, P } from 'src/typography/typography'
import Card from 'src/common/Card'
import FormHeader from './FormHeader'
import { useEffect, useState } from 'react'
import { windowContext } from 'src/util'
import { Box } from '@chakra-ui/layout'
import { useRouter } from 'next/router'
import { routes } from 'src/navigation/util'
import Link from 'next/link'

function LoginFormContainer({ children, ...rest }) {
  const router = useRouter()
  const [action, setAction] = useState(null)

  useEffect(() => {
    if (windowContext()) {
      setAction(window.localStorage.getItem('pl-action'))
    }
  }, [])

  const message = () => {
    if (router.query?.ref) {
      switch (router.query?.ref) {
        case 'project': {
          return 'Please log in to view this project'
        }
      }
    }

    if (router.query?.next) {
      switch (router.query?.next) {
        case routes.newProject: {
          return 'Please log in to create a project'
        }
      }
    }

    switch (action) {
      case 'delete-account': {
        return 'Please login to verify your identity. Your account will be deleted on succesful login.'
      }
      case 'share-project': {
        return 'Please login or create an account to share this project.'
      }
      case 'share-org': {
        return 'Please login or create an account to share this organization.'
      }
      case 'unauthenticated':
      case 'protectedRoute': {
        return 'Please login to continue'
      }
      default: return null
    }
  }

  const otherFormLink = router.route === routes.login ? (
    <P mt='base'>
      Don't have an account? <Link href={routes.signup} passHref><Hyperlink>Sign up</Hyperlink></Link>
    </P>
  ) : (
    <P mt='base'>
      Already have an account? <Link href={routes.login} passHref><Hyperlink>Login</Hyperlink></Link>
    </P>
  )

  return (
    <Box
      maxW={['sm', 'md', 'md']}
      mb={28}
      {...rest}
    >
      <Card
        width={['sm', 'md', 'md']}
        flexDirection='column'
        p={0}
        pt={0}
      >
        <FormHeader />
        {message() && (
          <P
            p='base'
            pb={0}
            textAlign='center'
            color='error.default'
          >
            {message()}
          </P>
        )}
        {children}
      </Card>
      {otherFormLink}
      <Caption mt={4}>
        Projectlink will never sell or use your data in any way without your explicit consent. You can delete your account at any time.
      </Caption>
    </Box>
  )
}

export default LoginFormContainer
