import { Box } from '@chakra-ui/react';
import { useAccent1, useAccent2 } from 'src/modeAwareColors';

/**
 * @derpecated - generally prefer <Box layerStyle='card' />
 * just because it's more versatile
 */
function Card({
  children,
  isClickable = false,
  ...rest }) {
  return (
    <Box
      my={4}
      d='inherit'
      maxWidth='720px'
      layerStyle='cardPadding'
      borderWidth='1px'
      borderColor={isClickable ? useAccent1() : useAccent2()}
      borderRadius='lg'
      boxShadow={isClickable ? `card` : ''}
      as={isClickable ? `a` : `section`}
      transition='box-shadow .3s '
      {...hoverStyles(isClickable)}
      {...rest}
    >
      {children}
    </Box>
  );
}

function hoverStyles(isClickable) {
  if (!isClickable) return {}
  return {
    _hover: { boxShadow: `cardHover`, transition: `box-shadow .3s` },
  }
}

export default Card
