import LoginButton from './Button'
import { FiMail } from 'react-icons/fi'

function EmailButton({ onClick, ...rest }) {
  return (
    <>
      <LoginButton
        onClick={onClick}
        leftIcon={<FiMail />}
        {...rest}
      >
        Continue with email
      </LoginButton>
    </>
  )
}

export default EmailButton
