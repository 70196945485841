import firebase from 'src/util/firebaseWeb'
import { useToast } from "@chakra-ui/react"
import Toast from 'src/common/Toast'
import projectService from 'src/access/project'
import { DomainObjects } from 'projectlink-common'
import organizationService from 'src/access/organization'
import { useRouter } from 'next/router'
import { routes } from 'src/navigation/util'
import useUserContext from './useUserContext'
import { useCallback } from 'react'
import { ActionTypes, clearActions } from 'src/util/locallyStoredActions'

/**
 * Run code triggered by values in localStorage.
 * Useful for requiring a reauthenntication before
 * sensitive actions like deleting account or changing
 * account email.
 */
const useLocallyStoredActions = () => {
  const toast = useToast()
  const router = useRouter()
  const { user, account } = useUserContext()

  return useCallback(async () => {
    const action = window.localStorage.getItem('pl-action')

    switch (action) {
      case ActionTypes.deleteAccount: {
        await firebase.auth().currentUser.delete()
        toast({
          position: 'bottom',
          render: () => (
            <Toast
              description='Account deleted'
            />
          )
        })
        await router.push(routes.home)
        break
      }

      case ActionTypes.shareProject: {
        const { email, message, entityId } = JSON.parse(window.localStorage.getItem('pl-action-payload'))
        window.localStorage.removeItem('pl-action-payload')

        const shareParams = {
          users: [{ email, type: DomainObjects.user, id: null }],
          orgs: [],
          senderDisplayName: account?.activeOrg?.displayName || user?.displayName,
          message,
        }

        await projectService.share(entityId, shareParams)
        await router.push(routes.project(entityId))

        toast({
          position: 'bottom',
          render: () => (
            <Toast
              description={`Project shared to ${email}`}
            />
          )
        })
        break
      }

      case ActionTypes.shareOrg: {
        const { email, entityId } = JSON.parse(window.localStorage.getItem('pl-action-payload'))
        window.localStorage.removeItem('pl-action-payload')

        const shareParams = {
          users: [{ email, type: DomainObjects.user, id: null }],
          orgs: [],
          senderDisplayName: account?.activeOrg?.displayName || user?.displayName,
        }

        await organizationService.share(entityId, shareParams)
        await router.push(routes.organization(entityId))

        toast({
          position: 'bottom',
          render: () => (
            <Toast
              description={`Organization shared to ${email}`}
            />
          )
        })
        break
      }

      case ActionTypes.redirect: {
        const route = window.localStorage.getItem('pl-action-payload')
        await router.push(route)
        break
      }

      default: {
        break
      }
    }

    window.localStorage.removeItem('pl-action')
    return action
  }, [user, account])
}

export default useLocallyStoredActions
