import { Icon, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { FiMail } from 'react-icons/fi'

const EmailInput = forwardRef<'input', InputProps>((props, ref) => {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents='none'
        children={<Icon
          as={FiMail}
          height='14px'
          color='primary.accent3'
        />}
      />
      <Input
        // @ts-ignore - i have no idea what this shit is
        ref={ref}
        name='email'
        {...props}
      />
    </InputGroup>
  )
})

export default EmailInput
