import { Button, ButtonProps } from '@chakra-ui/react';

function LoginButton(props: ButtonProps) {
  return (
    <Button
      size='md'
      width='100%'
      variant='outline'
      mb={4}
      {...props} />
  );
}

export default LoginButton