import { FirestoreCollections, OrganizationDocument, Project, ProjectDocument, ShareEntityPayload } from 'projectlink-common'
import { _fetch } from 'src/util/access'
import firebaseAdmin from 'src/util/firebaseAdmin'
import endpoints from './endpoints'

const create = async (data: Partial<Project>): Promise<{ id: string }> => {
  return _fetch(endpoints.projects(), {
    method: 'post',
    body: JSON.stringify(data),
  })
}

const get = async (projectId: string): Promise<ProjectDocument> => {
  return _fetch(endpoints.projects(projectId), {
    method: 'get',
  })
}

const update = async (id: string, data: Partial<ProjectDocument>) => {
  return _fetch(endpoints.projects(id), {
    method: 'put',
    body: JSON.stringify(data),
  })
}

const del = async (id: string, hardDelete: boolean = false) => {
  const qs = hardDelete ? '?hard=true' : ''

  return _fetch(`${endpoints.projects(id)}${qs}`, {
    method: 'delete',
  })
}

const sendInvites = async (
  id: string,
  payload: ShareEntityPayload,
) => {
  return _fetch(`${endpoints.projects(id)}/invites`, {
    method: 'post',
    body: JSON.stringify(payload),
  })
}

const share = async (
  id: string,
  payload: ShareEntityPayload,
) => {
  return _fetch(`${endpoints.projects(id)}/share`, {
    method: 'post',
    body: JSON.stringify(payload)
  })
}

const requestAccess = async (
  projectId: string,
) => {
  return _fetch(`${endpoints.projects(projectId)}/request-access`, {
    method: 'post',
  })
}

const projectService = {
  create,
  get,
  update,
  del,
  sendInvites,
  requestAccess,
  share,
}

export default projectService
