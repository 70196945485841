import { Box, Button, Text } from '@chakra-ui/react'
import { email } from '../util/validation'
import BackButton from './BackButton'
import useValidateOnChange from 'src/hooks/useValidateOnChange'
import EmailInput from 'src/common/EmailInput'

function EmailFieldSet(props) {

  const {
    name,
    onBack,
    display,
    register,
    isLoading,
    autoFocus = true,
  } = props

  const { onChange, isValid } = useValidateOnChange(email)

  // const onClick = () => {
  //   if (isValid) onNext()
  // }

  return (
    <Box
      display={display}
    >
      <Box layerStyle='cardPadding'>
        <Text
          as='label'
          textStyle='label'
          d='block'
          // @ts-ignore
          htmlFor='email'
        >
          Email address
        </Text>
        <EmailInput
          pl={8}
          ref={r => register(r, { validate: email })}
          onChange={onChange}
          name={name}
          id={name}
          type='email'
          placeholder='Email address...'
          autoFocus={autoFocus}
        />
      </Box>
      <Box
        layerStyle='cardFooter'
        alignItems='center'
        justifyContent='space-between'
      >
        <BackButton onClick={onBack} />
        <Button
          // mt='base'
          // mb={0}
          isLoading={isLoading}
          disabled={!isValid}
          type='submit'
        >
          Next →
        </Button>
      </Box>
    </Box>
  )
}

EmailFieldSet.displayName = 'EmailFieldSet'
export default EmailFieldSet
