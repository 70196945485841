import useInputValidation from './useInputValidation'

/**
 * useful for testing and setting an input value.
 * returns onChange handler, and whether validation
 * is currently passing
 * 
 * @param validationTest - function that returns a boolean, is invoked with e.target.value
 * @param setFormData - optional state setter
 */
function useValidateOnChange(validationTest, setFormData = _x => null) {
  const { isValid, validate } = useInputValidation(validationTest)

  const onChange = e => {
    const { target } = e
    validate(target.value)
    setFormData(prevState => ({ ...prevState, displayName: target.value }))
  }

  return {
    onChange,
    isValid,
    validate,
  }
}

export default useValidateOnChange