import { OrganizationDocument, Reference, ShareEntityPayload, User } from 'projectlink-common'
import { _fetch } from 'src/util/access'
import endpoints from './endpoints'

// returns the newly created document's ID
const get = async (orgId: string) => {
  return _fetch(endpoints.organizations(orgId), {
    method: 'get',
  })
}

const create = async (data: OrganizationDocument): Promise<{ id: string }> => {
  return _fetch(endpoints.organizations(), {
    method: 'post',
    body: JSON.stringify(data),
  })
}

const update = (id: string, data: Partial<OrganizationDocument>) => {
  return _fetch(endpoints.organizations(id), {
    method: 'put',
    body: JSON.stringify(data),
  })
}

export interface FreshnessBody {
  value: number,
}

const freshness = async (id: string, data: FreshnessBody) => {
  return Promise.all([
    fetch(`${endpoints.organizations(id)}/freshness`, {
      method: 'post',
      body: JSON.stringify(data),
    })
  ])
}

export interface InviteParams {
  email: string,
  sender: string,
  orgName: string,
  href: string,
}

/**
 * invite to become team member
 */
const invite = async (orgId: string, params: InviteParams[]) => {
  return fetch(`${endpoints.organizations(orgId)}/invites`, {
    method: 'post',
    body: JSON.stringify(params),
  })
}

const share = async (orgId: string, params: ShareEntityPayload) => {
  return fetch(`${endpoints.organizations(orgId)}/share`, {
    method: 'post',
    body: JSON.stringify(params),
  })
}

/**
 * accept an invite to become team member
 */
// const acceptInvite = (orgId: string, tokenId: string) => {
//   return _fetch(`${endpoints.organizations(orgId)}/accept-invite`, {
//     method: 'post',
//     body: JSON.stringify(tokenId),
//   })
// }

/**
 * request membership to an organization
 */
export interface RequestMembershipBody {
  actor?: Partial<User>,
}
const requestMembership = async (orgId: string, opts: RequestMembershipBody = null) => {
  return _fetch(`${endpoints.organizations(orgId)}/request-membership`, {
    method: 'post',
    body: JSON.stringify(opts),
  })
}

/**
 * accept someone's membership request
 */
export interface AcceptMembershipBody {
  tokenId: string,
}

const acceptMembership = async (orgId: string, params: AcceptMembershipBody = null) => {
  return _fetch(`${endpoints.organizations(orgId)}/accept-membership`, {
    method: 'post',
    body: JSON.stringify(params)
  })
}

const saveReference = async (orgId: string, payload: Reference) => {
  return _fetch(`${endpoints.organizations(orgId)}/references`, {
    method: 'post',
    body: JSON.stringify(payload),
  })
}

/**
 * careful, this is a hard delete
 * admin only
 */
const del = async (orgId: string) => {
  return _fetch(`${endpoints.organizations(orgId)}`, {
    method: 'delete'
  })
}

const organizationService = {
  get,
  del,
  create,
  update,
  freshness,
  invite,
  share,
  requestMembership,
  acceptMembership,
  // acceptInvite,
  saveReference,
}

export default organizationService
